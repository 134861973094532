.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.verticalHeader {
  writing-mode: vertical-rl;
  transform: rotate(200deg);
  text-align: center;
  max-height: 180px;
  padding-right:25%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.st0{fill:#A6228C;}
.st1{fill:#7DBC42;}
.st2{fill:#1A99D6;}
.st3{fill:#F26826;}
.st4{fill:none;}
.st5{fill:#F3A71E;}
.st6{fill:#1E2859;}
.st7{fill:none;stroke:#F26826;stroke-width:5;stroke-miterlimit:10;}
.st8{fill:#5B2C86;}
.st9{font-family:'SourceSansPro-SemiBold';}
.st10{font-size:150.0753px;}

.st20{fill:#5B2C86;}
.st21{fill:#F26926;}
.st22{fill:#F3A71E;}
.st23{fill:#1E2859;}
.st24{fill:#1A99D6;}
.st25{fill:#7DBC42;}
.st26{fill:#A6228C;}
.st27{fill:#F26826;}

