
.contentContainer {
    display: flex;
}

.contentContainer .iconContainer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-left: 10px;
}

.title {
    position: relative;
}

.topLine {
    width: 100%;
    height: 0;
    border: 8px solid #C33;

    top: 0;
}

.title .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.75);
}

.content {

}

.content .text {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.5);
}

.modal .dialogActions {
    display: flex;
    gap: 30px;
    padding: 20px;
}
