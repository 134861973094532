.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-repeat: 'repeat';
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
              0px 24px 38px 3px rgba(0, 0, 0, 0.14),
              0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 30px;
}

.alert {
  margin-bottom: 30px;
}

.btn {
  color: black;
  width: 100%;
}